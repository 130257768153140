.google-slides-container{
  position: relative;
  width: 100%;
  padding-top: 60%;
  overflow: hidden;
}

.google-slides-container iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
